import React from 'react'

import moment from 'moment'
import _ from 'lodash'

import LoadingPane from '../../components/Shared/LoadingPane'

const statuses = {
    closed: {
        color: '#3BC86B',
        text: 'Complete'
    },
    open: {
        color: '#73787B',
        text: 'Pending'
    }
}

const HealthSnap = (props) => {
    const { goToHealthSnapRoute, match, state } = props
    const { answers, healthSnap, loading, qHash } = state

    if (loading) {
        return <LoadingPane />
    } else {
        let section = qHash[match.params.section],
            sectionName = section.conditions[0].value

        return (
            <div className='healthsnap-section-view full-screen-view'>
                <div className='toolbar'>
                    <button className='cancel-button' onClick={goToHealthSnapRoute}>Cancel</button>

                    <div className='page-header-frame'>
                        <div className='section-name'>{sectionName}</div>

                        <div className='section-detail'>
                            <div className='section-label'>{`Section ${parseInt(match.params.section) + 1} of ${Object.keys(qHash).length} -`}</div>
                            <div className='status bold' style={{ color: statuses['closed'].color }}>{statuses['closed'].text}</div>
                        </div>
                    </div>

                    <button className='print-button'>Print Packet</button>
                </div>

                <div className='content-frame'>
                    {/* only show default question values when they are present */}
                    {section.questions.length > 0 ?
                        <div className='section-frame'>
                            <div className='section-document-frame'>
                                <div className='section-header'>{sectionName}</div>

                                <div className='form-frame'>
                                    <ul className='questions-list-view'>
                                        {section.questions.map(question => {
                                            const { key } = question

                                            let formattedKey = key.split('.')[1].replace(/_/g, ' '),
                                                formattedAddrKey = key.split('.')[3],
                                                answer = answers[key]

                                            return (
                                                <li className='section-question-cell' key={key}>
                                                    <div className='section-key'>{(formattedKey === 'address') ? formattedAddrKey : formattedKey}</div>
                                                    <div className={`section-value ${answer ? '' : 'hint'}`}>{answer ? (question.kind === 'date') ? moment(answer).format('MMM DD, YYYY') : answer : '-'}</div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        :
                        null
                    }

                    <ul className='groups-list-view'>
                        {section.groups.map(group => {
                            if (group.label === 'emergency_contact') {
                                let arr = answers[`person.${group.label}`],
                                    data = (arr.length > 0) ? JSON.parse(arr) : []

                                return (
                                    <div className='section-frame'>
                                        <div className='section-document-frame'>
                                            <div className='section-header'>Emergency Contacts</div>

                                            <div className='section-table-view'>
                                                <div className='table-headers'>
                                                    <div className='table-header name'>Name</div>
                                                    <div className='table-header relationship'>Relationship</div>
                                                    <div className='table-header address'>Address</div>
                                                    <div className='table-header phone'>Contact Phone Number</div>
                                                </div>

                                                {data.length > 0 ?
                                                    <ul className='section-list-view'>
                                                        {data.map((o, i) => {
                                                            const { fullName, phone, relationship } = o

                                                            let address

                                                            return (
                                                                <li className='section-cell' key={i}>
                                                                    <div className='cell-label name'>{fullName || '-'}</div>
                                                                    <div className='cell-label relationship'>{relationship || '-'}</div>
                                                                    <div className='cell-label address'>{address || '-'}</div>
                                                                    <div className='cell-label phone'>{phone || '-'}</div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    :
                                                    <div style={{ fontSize: 12, color: '#73787B', fontStyle: 'italic', textAlign: 'center', padding: 16.5 }}>There are no emergency contacts to display.</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (group.label === 'insurance_info') {
                                let arr = answers['person.insurances'],
                                    data = (arr.length > 0) ? JSON.parse(arr) : []

                                return (
                                    <ul className='insurances-list-view'>
                                        {data.map((o, i) => {
                                            const { carrier, memberNumber, groupNumber, primaryName, status } = o

                                            return (
                                                <div className='section-frame' key={i}>
                                                    <div className='section-document-frame'>
                                                        <div className='section-header'>{carrier}</div>

                                                        <div className='form-frame'>
                                                            <ul className='questions-list-view'>
                                                                <li className='section-question-cell' key={0}>
                                                                    <div className='section-key'>Insurance Carrier</div>
                                                                    <div className='section-value'>{carrier || '-'}</div>
                                                                </li>

                                                                <li className='section-question-cell' key={1}>
                                                                    <div className='section-key'>Member Number</div>
                                                                    <div className='section-value'>{memberNumber || '-'}</div>
                                                                </li>

                                                                <li className='section-question-cell' key={2}>
                                                                    <div className='section-key'>Group Number</div>
                                                                    <div className='section-value'>{groupNumber || '-'}</div>
                                                                </li>

                                                                <li className='section-question-cell' key={3}>
                                                                    <div className='section-key'>Primary Card Holder</div>
                                                                    <div className='section-value'>{primaryName || '-'}</div>
                                                                </li>

                                                                <li className='section-question-cell' key={4}>
                                                                    <div className='section-key'>Insurance Status</div>
                                                                    <div className='section-value ' style={{ color: (status === 'active') ? '#3BC86B' : '#FB3156' }}>{_.capitalize(status) || '-'}</div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                )
                            } else if (group.label === 'family_history') {
                                let arr = answers[`person.${group.label}`],
                                    data = (arr.length > 0) ? JSON.parse(arr) : []

                                return (
                                    <div className='section-frame'>
                                        <div className='section-document-frame'>
                                            <div className='section-header'>Family Members</div>

                                            <div className='section-table-view'>
                                                <div className='table-headers'>
                                                    <div className='table-header name'>Relationship</div>
                                                    <div className='table-header relationship'>Status</div>
                                                    <div className='table-header status'>Age</div>
                                                    <div className='table-header phone'>Medical Issues</div>
                                                </div>

                                                {data.length > 0 ?
                                                    <ul className='section-list-view'>
                                                        {data.map((o, i) => {
                                                            const { alive, dob, issues, relationship } = o

                                                            let relationships = {
                                                                'grandmother_mom': "Grandmother (Mom's Side)",
                                                                'grandmother_dad': "Grandmother (Father's Side)",
                                                                'grandfather_mom': "Grandfather (Mom's Side)",
                                                                'grandfather_dad': "Grandfather (Father's Side)"
                                                            }, formattedRelationship = (relationship.includes('_')) ? relationships[relationship] : relationship

                                                            return (
                                                                <li className='section-cell' key={i}>
                                                                    <div className='cell-label name'>{_.capitalize(formattedRelationship) || '-'}</div>
                                                                    <div className='cell-label relationship'>{(typeof alive !== 'undefined') ? (Boolean(alive) ? 'Alive' : 'Deceased') : '-'}</div>
                                                                    <div className='cell-label status'>{dob ? `${moment().diff(moment(dob), 'years')} yrs` : '-'}</div>
                                                                    <div className='cell-label phone'>{issues ? issues.toString().replace(/,/g, ', ') : '-'}</div>
                                                                </li>
                                                            )
                                                        })}
                                                    </ul>
                                                    :
                                                    <div style={{ fontSize: 12, color: '#73787B', fontStyle: 'italic', textAlign: 'center', padding: 16.5 }}>There are no items to display.</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            } else if (group.label === 'health_history') {
                                let allergies = answers['person.allergies'],
                                    issues = answers['person.issues'],
                                    medications = answers['person.medications'],
                                    groups = [
                                        { header: 'Medications', data: allergies ? JSON.parse(allergies) : [] },
                                        { header: 'Medical Issues', data: allergies ? JSON.parse(issues) : [] },
                                        { header: 'Allergies', data: allergies ? JSON.parse(medications) : [] }
                                    ]

                                return (
                                    <ul className='groups-list-view'>
                                        {groups.map((group, i) => {
                                            const { data, header } = group

                                            return (
                                                <div className='section-frame' key={i}>
                                                    <div className='section-document-frame'>
                                                        <div className='section-header'>{header}</div>

                                                        <div className='section-table-view'>
                                                            <div className='table-headers'>
                                                                <div className='table-header title'>Title</div>
                                                                <div className='table-header start-date'>Start Date</div>
                                                                <div className='table-header status'>Status</div>
                                                            </div>

                                                            {data.length > 0 ?
                                                                <ul className='section-list-view'>
                                                                    {data.map((o, i) => {
                                                                        const { date, name, status } = o

                                                                        return (
                                                                            <li className='section-cell'>
                                                                                <div className='cell-label title bold'>{name || '-'}</div>
                                                                                <div className='cell-label start-date'>{moment(date).format('MMM DD, YYYY') || '-'}</div>
                                                                                <div className='cell-label status bold' style={{ color: (status === 'active') ? '#3BC86B' : '#FB3156' }}>{_.capitalize(status) || '-'}</div>
                                                                            </li>
                                                                        )
                                                                    })}
                                                                </ul>
                                                                :
                                                                <div style={{ fontSize: 12, color: '#73787B', fontStyle: 'italic', textAlign: 'center', padding: 16.5 }}>There are no items to display.</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                )
                            } else if (group.label === 'social_history') {
                                let arr = JSON.parse(answers['person.social_history']),
                                    data = (arr.length > 0) ? arr : [{ type: 'alcohol', status: undefined }, { type: 'drugs', status: undefined }, { type: 'exercise', status: undefined }, {type: 'tobacco', status: undefined}]

                                return (
                                    <ul className='groups-list-view'>
                                        {data.map((group, i) => {
                                            const { freq, status, type } = group

                                            return (
                                                <div className='section-frame' key={i}>
                                                    <div className='section-document-frame'>
                                                        <div className='section-header'>{_.capitalize(type)}</div>

                                                        <div className='section-table-view'>
                                                            <div className='table-headers'>
                                                                <div className='table-header title long'>Status</div>
                                                                <div className='table-header start-date'>Frequency</div>
                                                            </div>

                                                            <ul className='section-list-view'>
                                                                <li className='section-cell' key={i}>
                                                                    <div className='cell-label title long bold'>{(typeof status !== 'undefined') ? (Boolean(status) ? 'Yes' : 'No') : '-'}</div>
                                                                    <div className='cell-label start-date'>{_.capitalize(freq) || '-'}</div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </ul>
                                )
                            } else {
                                return (
                                    <div>{`Section not found: ${group.label}`}</div>
                                )
                            }
                        })}
                    </ul>
                </div>
            </div>
        )
    }
}

export default HealthSnap
import React from 'react'
import { graphql } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'

import OrgQuery from '../../graphql/queries/HealthSnap'
import UserQuery from '../../graphql/queries/HealthSnap/User'

import HealthSnapPDF from '../../components/HealthSnap/PDF'

import LoadingPane from '../../components/Shared/LoadingPane'

class HealthSnapPDFContainer extends React.Component {
    state = {
        groupIndex: 0,
        loading: true,
        onload: true,
        qHash: {}
    }

    componentDidMount() {
        this.setup()
    }

    componentDidUpdate() {
        this.setup()
    }

    setup = () => {
        const { loading, org } = this.props.orgQuery
        const { onload } = this.state

        if (onload && !loading) {
            let healthSnap = org.healthSnaps.nodes[0],
                answers = {}

            healthSnap.assessment.answersFromUser.forEach(o => answers[o.id] = o.answer)

            this.setState({
                answers,
                healthSnap,
                onload: false
            }, this.setupUser)
        }
    }

    setupUser = () => {
        const { match, userQuery } = this.props

        userQuery.refetch({ user: this.state.healthSnap.user.id }).then(response => {
            const { user } = response.data.viewing

            let answers = {},
                healthSnap = user.healthSnaps.nodes.find(h => h.id === match.params.healthSnap)

            healthSnap.assessment.answersFromUser.forEach(o => {
                if (o.answer) {
                    const { data, key } = o.answer

                    answers[key] = (data.length === 1) ? data[0] : data
                }
            })

            console.log(answers)

            this.setState({
                answers,
                user
            }, () => this.setupAssessment(this.state.healthSnap.assessment.program))
        }).catch(error => {
            console.log(error)
        })
    }

    setupAssessment = (program) => {
        const { actions } = program
        const { groupIndex } = this.state

        let that = this,
            hash = {};

        actions.forEach(function (action, index) {
            const TYPE = action[0]

            if (TYPE === "group") {
                that.deserializeGroup(hash, action, index)
            } else if (TYPE === "exit") {
                that.deserializeExit(hash, action, index)
            } else if (TYPE === "question") {
                that.deserializeQuestion(hash, action, index)
            } else {
                console.log('ERROR: INVALID TYPE')
            }
        })

        if (actions[groupIndex][0] === "exit") {
            let isCondition = this.checkConditions(hash[groupIndex]);

            this.setState({
                loading: false,
                qHash: hash,
                groupIndex: isCondition ? actions.length : groupIndex + 1
            }, this.componentDidUpdate)
        } else {
            this.setState({
                loading: false,
                qHash: hash,
                questions: hash[groupIndex].questions
            })
        }
    }

    deserializeCondition = (condition) => {
        return Object.keys(condition).map(function (key) {
            if (typeof condition[key] === "string") {
                return {
                    operator: key,
                    type: "string",
                    value: condition[key]
                }
            } else {
                return {
                    operator: key,
                    type: condition[key][0],
                    key: condition[key][1]
                }
            }
        })
    }

    deserializeGroup = (parentGroup, group, index) => {
        const that = this
        const CONDITION = group[1]
        const PARAMS = group[2]

        // key does not exist
        if (!parentGroup[index]) {
            parentGroup[index] = {
                completed: false,
                conditions: this.deserializeCondition(CONDITION),
                questions: []
            }
        }

        if (PARAMS) {
            PARAMS.forEach(function (param, i) {
                const TYPE = param[0]
                // const CONDITION = param[1]

                if (TYPE === "question") {
                    parentGroup[index].questions.push(param[1])
                } else {
                    that.deserializeGroup(parentGroup[index], param, i)
                }
            })
        } else {
            console.log('ERROR: GROUP HAS NO PARAMS.')
        }
    }

    deserializeExit = (parentGroup, action, index) => {
        const CONDITION = action[1].if

        let i = 1,
            conditions = [];

        while (i < CONDITION.length) {
            conditions.push(this.deserializeCondition({ if: CONDITION[i] })[0])
            i++;
        }

        parentGroup[index] = {
            conditions: conditions
        }
    }

    deserializeQuestion = (parentGroup, action, index) => {
        parentGroup[index] = {
            questions: [action[1]]
        }
    }

    goToHealthSnapRoute = () => {
        const { history, match } = this.props

        history.push(`/healthsnaps/${match.params.healthSnap}`)
    }

    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <HealthSnapPDF
                {...this.props}
                state={this.state}
            />
    }
}

export default compose(
    graphql(OrgQuery, {
        name: 'orgQuery',
        options: (props) => ({
            variables: {
                id: '34',
                healthSnap: [props.match.params.healthSnap]
            }
        })
    }),
    graphql(UserQuery, {
        name: 'userQuery',
        options: (props) => ({
            variables: {
                user: '',
                healthSnap: [props.match.params.healthSnap]
            }
        })
    })
)(HealthSnapPDFContainer)
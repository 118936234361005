import React from 'react'
import { graphql } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'
import Cookie from 'js-cookie'

import LoginMutation from '../../graphql/mutations/Login'

import Login from '../../components/Login'

class LoginContainer extends React.Component {
    state = {
        // email: 'bob+provider@example.com',
        // password: 'Welcome2022!',
        loading: false
    }

    handleChange = (key, value) => {
        this.setState({
            [key]: value
        })
    }

    login = () => {
        const { loginMutation } = this.props
        const { email, password } = this.state

        this.setState({
            loading: true
        }, () => {
            loginMutation({
                variables: {
                    input: {
                        email,
                        password
                    }
                }
            }).then(response => {
                const { errors, user } = response.data.login

                this.handleChange('loading', false)

                if (user && user.token) {
                    let role = user.roles.find(r => r.role === 'provider')

                    Cookie.set('msp-provider-portal', user.token)
                    Cookie.set('msp-provider-portal-role', role.orgId)
                    
                    window.location.reload()
                } else {
                    window.alert(errors[0].message)
                }
            }).catch(error => {
                this.handleChange('loading', false)

                window.alert(error.message)
            })
        })
    }

    render() {
        return (
            <Login
                handleChange={this.handleChange}
                login={this.login}
                state={this.state}
            />
        )
    }
}

export default compose(
    graphql(LoginMutation, { name: 'loginMutation' })
)(LoginContainer)
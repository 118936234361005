import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import Cookie from 'js-cookie'

const httpLink = createHttpLink({
    // uri: 'https://msp-stg.synnovahealth.com/gql',
    uri: 'https://api.sandbox.lifewallet.com/gql'
})

const authLink = setContext(({ operationName }, props) => {
    const token = Cookie.get('msp-provider-portal');

    return {
        headers: {
            authorization: (token && operationName !== 'LoginMutation') ? `${token}` : "",
        }
    }
})

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache()
})

export default client
import React from 'react'

const HealthSnapPDF = (props) => {
    const { state } = props

    console.log(state)

    return (
        <div className='healthsnap-pdf-view'>
            <div className='pdf-header-frame'>
                <div>LifeWallet Intake Form</div>
            </div>
        </div>
    )
}

export default HealthSnapPDF
import React from 'react'
import { graphql } from '@apollo/client/react/hoc'

import { flowRight as compose } from 'lodash'

import OrgQuery from '../../graphql/queries/HealthSnap'

import HealthSnap from '../../components/HealthSnap'

import LoadingPane from '../../components/Shared/LoadingPane'

class HealthSnapContainer extends React.Component {
    state = {
        onload: true
    }

    componentDidMount() {
        this.setup()
    }

    componentDidUpdate() {
        this.setup()
    }

    setup = () => {
        const { loading, org } = this.props.orgQuery
        const { onload } = this.state

        if (onload && !loading) {
            let healthSnap = org.healthSnaps.nodes[0]

            this.setState({
                sections: this.setupAssessment(healthSnap.assessment.program),
                healthSnap,
                onload: false
            })
        }
    }

    setupAssessment = (program) => {
        const { actions } = program

        let that = this,
            hash = {};

        actions.forEach(function (action, index) {
            const TYPE = action[0]

            if (TYPE === "group") {
                that.deserializeGroup(hash, action, index)
            }
        })

        return Object.keys(hash).map(key => hash[key])
    }

    deserializeCondition = (condition) => {
        return Object.keys(condition).map(function (key) {
            if (typeof condition[key] === "string") {
                return {
                    operator: key,
                    type: "string",
                    value: condition[key]
                }
            } else {
                return {
                    operator: key,
                    type: condition[key][0],
                    key: condition[key][1]
                }
            }
        })
    }

    deserializeGroup = (parentGroup, group, index) => {
        const that = this
        const CONDITION = group[1]
        const PARAMS = group[2]

        // key does not exist
        if (!parentGroup[index]) {
            parentGroup[index] = {
                completed: false,
                conditions: this.deserializeCondition(CONDITION),
                questions: []
            }
        }

        if (PARAMS) {
            PARAMS.forEach(function (param, i) {
                const TYPE = param[0]
                // const CONDITION = param[1]

                if (TYPE === "question") {
                    parentGroup[index].questions.push(param[1])
                } else {
                    that.deserializeGroup(parentGroup[index], param, i)
                }
            })
        } else {
            console.log('ERROR: GROUP HAS NO PARAMS.')
        }
    }

    goToDashboardRoute = () => {
        this.props.history.push('/dashboard')
    }

    goToHealthSnapPdfRoute = () => {
        this.props.history.push(`/healthsnaps/${this.props.match.params.healthSnap}/pdf`)
    }
     
    goToHealthSnapSectionRoute = (i) => {
        const { history, match } = this.props

        history.push(`/healthsnaps/${match.params.healthSnap}/sections/${i}`)
    }

    render() {
        return this.state.onload ?
            <LoadingPane />
            :
            <HealthSnap
                goToDashboardRoute={this.goToDashboardRoute}
                goToHealthSnapPdfRoute={this.goToHealthSnapPdfRoute}
                goToHealthSnapSectionRoute={this.goToHealthSnapSectionRoute}
                state={this.state}
            />
    }
}

export default compose(
    graphql(OrgQuery, {
        name: 'orgQuery',
        options: (props) => ({
            variables: {
                id: '34',
                healthSnap: [props.match.params.healthSnap]
            }
        })
    })
)(HealthSnapContainer)